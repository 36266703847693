import * as React from "react";
import {  Modal } from "@itwin/itwinui-react";
import { IModelApp, IModelConnection, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { UiFramework } from "@itwin/appui-react";
import Dialog from "@material-ui/core/Dialog";
import "./stylesheet.scss";
import { Logger } from "../../api/logging";
import { ConfigManager } from "../../../config/ConfigManager";
import DatabaseApiClient from "../../../services/graphql/database-api/database-api-client";
import { setRadLevelWorkflow } from "../../../store/detectedData/apiDataActionTypes";
import { store } from "../../../store/rootReducer";

const DeleteMount = (props)=>{
    const [showPopUp, setShowPopUp] = React.useState(false)
    const [mountInfo, setMountInfo] = React.useState({elevation:"",location:""})
    const [mountDetails, setMountDetails] = React.useState({elevation: 0,id: "",location: "",manufacturer: "",modelName: "",type: ""})
    const [containsAntenna, setContainsAntenna] = React.useState(false)

    React.useEffect(()=>{
        initialData()
    },[props.imodel])

    const initialData = async()=>{
        let antennadata = await getAntennaData(props.imodel)
        let mountdata = await getMountData(props.imodel)
        if(mountdata){
            let selectedMount = store.getState().detectedData.selectedObjectInformation.objectProperties.objectIds[0] ?? props.mountSelected;
            let particularMount = mountdata?.filter(allmount=>allmount[0] == selectedMount )[0]
            // let containsAntenna = antennadata.filter(allmount=>allmount[0] == selectedMount.id )
    
            if(particularMount && particularMount.length){
                
                let mountProperty = {id:particularMount[0],modelName:particularMount[1],elevation:particularMount[2],manufacturer:particularMount[3],location:particularMount[4],type:particularMount[5]}
                
                if(antennadata){
                 
    
                    let antennas = antennadata.filter(antenna=>{
                        const mountLocation = mountProperty.location.replace(/[a-z]/g, '');
                        const antennaLocation = antenna[5].replace(/[a-z]/g, '');//both the location will be comapred as we are checking antenna having location FaceA with mount location LegA
                        if((antenna[3] <= parseFloat(mountProperty.elevation)+1 &&antenna[3] >= parseFloat(mountProperty.elevation)-1) && mountProperty.type.includes("Platform") )return antenna
                        else if((antenna[3] <= parseFloat(mountProperty.elevation)+1 &&antenna[3] >= parseFloat(mountProperty.elevation)-1) && ((antenna[5] == mountProperty.location) || mountLocation[1] == antennaLocation[1]))return antenna
                    
                    }) ;           
                  
                    if(antennas && antennas.length > 0){
                        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, "Try deleting selected Mount after removing all equipment.", "", OutputMessageType.Toast));
                        setShowPopUp(false)
                        UiFramework.content.dialogs.close(
                            "deleteObject"
                
                        )
                    }else{
                        setShowPopUp(true)
    
                    }
                    setContainsAntenna(antennas.length>0?true:false)
                    setMountDetails(mountProperty)
                }
                
                // setMountInfo(mountProperty)
            }
        }
    }
    const getAntennaData = async(imodel: IModelConnection) =>{
        try {
            if (imodel.isBlank) {
                return undefined;
            }
            let records: any = []
            for await (const record of imodel.query("select ECClassId, ECInstanceId, Name, Elevation, Manufacturer,Location, ModelName, Type, Azimuth, LateralOffset, VerticalOffset, HorizontalOffset, Height, Width, Depth, Weight from OpenTower.Antenna")) {
                 records.push(record);
            }
            return records
        } catch (e) {
         Logger.error("Error while getting Antenna data",e);
        }
    
        return undefined;
    }
    const getMountData = async(imodel: IModelConnection) =>{
        try {
            if (imodel.isBlank) {
                return undefined;
            }
            let records: any = []
            for await (const record of imodel.query("select  ECInstanceId, ModelName, Elevation, Manufacturer,Location, Type from OpenTower.Mount")) {
                 records.push(record);
            }
            return records
        } catch (e) {
            Logger.error("Error while getting Mount data",e);
        }
    
        return undefined;
    }
    const closeModal = ()=>{
        setShowPopUp(!showPopUp)
        UiFramework.content.dialogs.close(
            "deleteObject"

        )
    }
    

    const handleSave = async()=>{
        setContainsAntenna(true)
        let variable = {
            mountAdditionGeometryDetails:{
                mountSectionGeometryDetails:[],
              } ,mountChangeGeometryDetails:{
                mountSectionGeometryDetails:[]
            },   deleteGeometryDetails:[{
                iModelElementId:mountDetails.id
            }]
        }
        let data = await DatabaseApiClient.mountImodelModify(variable,ConfigManager.projectId)
        if(data  && data.httpStatusCode == 202){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.None, "Mount Deletion Process has been started", "", OutputMessageType.Toast));
            setShowPopUp(false)
            setContainsAntenna(false)
            store.dispatch(setRadLevelWorkflow({
                status : true,
                typeOfWorkflow: "Delete"
            }))

        }else if(data && data.httpStatusCode == 400){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, data.statusMessage, "", OutputMessageType.Toast));
            setShowPopUp(false)
            setContainsAntenna(false)
            UiFramework.content.dialogs.close(
                "deleteObject"
            )
        }

    }
    return(
        <>
       {showPopUp && <Dialog
    //    height={350} width={700}  Y={130}
                // closeHandle={() => closeModal()}
                // primaryButtonLabel={"Yes"}
                // title={"Delete Mount"}
                open = {showPopUp}
                // primaryButtonHandle={() => handleSave()}
                // isPrimaryDisabled={containsAntenna}
                onClose={() => closeModal()}
                // secondaryButtonLabel="No"
            >
                <div className="delete-mount-container">
                <div className="delete-mount-heading">
                    Are you sure you want to delete the selected Mount?
                </div>
                <div>
                    <button className="delete-mount-save-btn" onClick={handleSave}>Sure</button>
                    <button className="delete-mount-cancel-btn" onClick={closeModal}>Cancel</button>

                </div>

                </div>


            </Dialog>
            }
        </>
    )
}

export default DeleteMount