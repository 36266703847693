import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { AccessToken } from "@itwin/core-bentley";
import { store } from "../../store/rootReducer";
import { RootState } from "../../store/States";
import { ConfigManager } from "../../config/ConfigManager";

let accessToken: AccessToken, projectId: string, openToweriQUrl: string, privateAccessToken: AccessToken;

const listener = () => {
    setParameters(store.getState());
}

function setParameters(state: RootState) {
    accessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
    projectId = ConfigManager.projectId!;
    openToweriQUrl = ConfigManager.openToweriQUrl!;
    privateAccessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
}

store.subscribe(listener);

export default class DefectsClient {
    constructor() { }
  //----------------------------------------------------------------------
  public static async getBaseAltitude(token: string): Promise<any> {
    // const projectId = ConfigManager.projectId;
    const url: string = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/BaseAltitude`;

    return fetch(url, {
      method: "get",
      headers: {
        Authorization: token,
      },
    }).then(async (response: Response) => {
      if (!response.ok) {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
          "This feature is not supported for this project.", "", OutputMessageType.Toast));
        return null;
      }
      return response.json();
    }).then((data: any) => {
      return data;
    });
  }
  //----------------------------------------------------------------------
  public static async getDefectPinsJson(token: string): Promise<any> {
      // const projectId = ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Defect/Pin`;
    return fetch(url, {
      method: "get",
      headers: {
        Authorization: token,
      },
    }).then(async (response: Response) => {
      if (!response.ok) {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
          "This feature is not supported for this project.", "", OutputMessageType.Toast));
        return null;
      }
      return response.json();
    }).then((data: any) => {
      return data;
    });
  }
  //----------------------------------------------------------------------
  public static async getDefectBoxJson(token: string): Promise<any> {
    // const projectId = ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Defect/Box`;
    return fetch(url, {
      method: "get",
      headers: {
        Authorization: token,
      },
    }).then(async (response: Response) => {
      if (!response.ok) {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
          "This feature is not supported for this project.", "", OutputMessageType.Toast));
        return null;
      }
      return response.json();
    }).then((data: any) => {
      return data;
    });
  }
  //----------------------------------------------------------------------
  public static async getDefectsJson(token: string): Promise<any> {
    // const projectId = ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Defect/Automated`;
    // const url = `${decodeURIComponent(
    //  window.location.origin
    // )}/api/Defects/` + projectId;

    return fetch(url, {
      method: "get",
      headers: {
        Authorization: token,
      },
    }).then(async (response: Response) => {
      if (!response.ok) {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
          "This feature is not supported for this project.", "", OutputMessageType.Toast));
        return null;
      }
      return response.json();
    }).then((data: any) => {
      return data;
    });
  }
  //----------------------------------------------------------------------
  public static async getDefectDetectionStatusJson(token: string): Promise<any> {
    // const projectId = ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/DefectDetection/Status`;

    return fetch(url, {
      method: "get",
      headers: {
        Authorization: token,
      },
    }).then(async (response: Response) => {
      if (!response.ok) {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,
          "This feature is not supported for this project.", "", OutputMessageType.Toast));
        return null;
      }
      return response.json();
    }).then((data: any) => {
      return data;
    });
  }
  //-------------------------------------------------------------------------
  public static async postDefectDetectionRunJson(token: string, type: string): Promise<any> {
    // const projectId = ConfigManager.projectId;
    // let type ="SCREWS"
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/DefectDetection/Run?type=${type}`;

    return fetch(url, {
      method: "post",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    }).then(async (res: Response) => {
      if (!res.ok) {
        return null;
      }
      return "successful";
    }).then((data: any) => {
      return data;
    });
  }
  //-------------------------------------------------------------------------
    public static async putPinDefectDataJason(token: string, defectData: any) {
        if (!defectData) {
            return;
        }
        // const projectId = ConfigManager.projectId;
    const url = `${ConfigManager.openToweriQUrl}/v1.0/${ConfigManager.projectId}/Defect/Pin`;
        return fetch(url, {
            method: "post",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(defectData),
        }).then(async (res: Response) => {
            if (!res.ok) {
                return null;
            }
            return "Success.";
        }).then((data: any) => {
            return data;
        });
    }
}
