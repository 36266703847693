import { ToolbarItemUtilities } from "@itwin/appui-abstract";
import { StagePanelLocation, SyncUiEventDispatcher, UiFramework, WidgetState } from "@itwin/appui-react";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import PinTagClient from "../../api/pinTagClient";
import { AddPinAnnotationTool } from "./AddPinAnnotationTool";
import { DTVActions } from "../../../store/Actions";
import { PropertyTableType, RootState } from "../../../store/States";
import { SyncUiEventIds } from "../../../store/redux-types";
import { store } from "../../../store/rootReducer";
import { DefectsDecorator } from "../../tools/decorators/DefectsDecorator";
import { DigitalTwinViewerApp } from "../../../api/DigitalTwinViewerApp";

const getAccessToken = (state: RootState) => {
               
    return state.auth.accessTokenStatePrivateAPI.accessToken;
  }

export  const clearOldMarkers = () => {
    for (const dec of IModelApp.viewManager.decorators) {
        if (dec.constructor.name.includes("DefectsDecorator")) {
            for (let d = 0; d < DefectsDecorator.createdDefectStore.length; d++) {
                if (DefectsDecorator.createdDefectStore[d].position != DefectsDecorator.lastCreatedPinDefect?.position) {
                    (dec as DefectsDecorator).removeDefectMarkerObject(DefectsDecorator.createdDefectStore[d]!)
                }
            }
            //(dec as DefectsDecorator).removeDefectMarkerObject(DefectsDecorator.lastCreatedPinDefect!);
            IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
        }
    }
}
export const HighlightPinAnnotations = () => {

    return ToolbarItemUtilities.createActionButton(
        "Highlight-pin-annotations", 
        110, 
        "icon-crop", 
        "Highlight Pin Annotaions",
        async () => executePinAnnotation(true)
    );
}

export const executePinAnnotation = async(showMessage:boolean = true)=>  {
    const highlightStates = store.getState().dtvState.applicationState.highlightStates;
    store.dispatch(DTVActions.updateHighlightState({...highlightStates, annotations: !highlightStates.annotations}))
    
    if(highlightStates.annotations)SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Annotations_UnHighlighted);
    // else if(store.getState().dtvState.applicationState.licenseStates.inspectionLicense.licenseEnabled)SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Annotations_Highlighted);       
    else if(DigitalTwinViewerApp.licenseStates.inspectionLicense.licenseEnabled)SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.Annotations_Highlighted);       

    let showPinTags = false;
    let  copyPinSelected = {
        isEditModeActive : false,
        showPinTags: false,
        selectedMarkerJson :{}
    }
    //Check if decorator exist and set the state to show on delete accordingly.
    for (const dec of IModelApp.viewManager.decorators) {
      if (dec.constructor.name.includes("DefectsDecorator")) {
        showPinTags = false;
            break;
          }
        else {
            showPinTags = true;
        }
      }
    //get the data for the Tag information
    
    if (showPinTags) {
        const token:any =  getAccessToken(store.getState());

      let data = await PinTagClient.getPinTags(token);
                    if (data != undefined) {
        
            // this.setState({ enabledForTagEditDelete: false });//enable/disable the delete button.
            copyPinSelected.isEditModeActive = false;
            
            DefectsDecorator.selectedMarkerJson = { fullList: true, data: data }
            // clearOldMarkers();
            DefectsDecorator.lastCreatedPinDefect = undefined;//make this undefined so that the Pinslist.tsx condition 'if(DefectsDecorator.lastCreatedPinDefect != undefined)' does not trigger.
            AddPinAnnotationTool.refreshAllMarkers();//Refresh all markers including the data after save is pressed, so that the user can continue working with updated data
            const pinWidget :any= UiFramework.frontstages.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PinListWidget");

            store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_PINS_ANNOTATION_LIST))
            if(pinWidget){
              
              if(pinWidget !== undefined && pinWidget.state!=0){
                pinWidget.setWidgetState(WidgetState.Open);
                }
                else{
          
                    pinWidget.setWidgetState(WidgetState.Hidden);
                } 
            }
            //if decorator exist use the existing to append decorator entites
            // let hasDecorator: boolean = false;
            // for (const dec of IModelApp.viewManager.decorators) {
            //     if (dec.constructor.name.includes("DefectsDecorator")) {
            //       hasDecorator = true;
            //       (dec as DefectsDecorator).terminate();
            //         (dec as DefectsDecorator).loadPinTagMarkers(data);
            //         // this._allDefectDecorator = dec;
            //         IModelApp.viewManager.selectedView?.invalidateDecorations();
            //         IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
            //       }
            //     }
            //     if (!hasDecorator) {
            //       const allDefs = new DefectsDecorator();
            //       allDefs.loadPinTagMarkers(data)
                //   this._allDefectDecorator = IModelApp.viewManager.addDecorator(allDefs);
                //   IModelApp.viewManager.selectedView?.invalidateCachedDecorations(allDefs);
                // }
              }else if(data == null && showMessage)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning,"Data Does Not Exist", "", OutputMessageType.Toast));

            //   store.dispatch(setNewPinAnnotation(copyPinSelected));
            
        //SampleToolWidget.showPinTags = !SampleToolWidget.showPinTags;//flip the state
        return;
    }
    else {
        executeUnhighlightPin()
        return;
    //   this.setState({ enabledForTagEditDelete: true });//enable/disable the delete button.
  
    }
}

export const executeUnhighlightPin = () => {
    // copyPinSelected.isEditModeActive = false;
    //Clean and delete the decorator from view manager
    for (const dec of IModelApp.viewManager.decorators) {
        if (dec.constructor.name.includes("DefectsDecorator")) {
            (dec as DefectsDecorator).terminate();
            IModelApp.viewManager.dropDecorator(dec);
        }
    }
   
    
    
    //SampleToolWidget.showPinTags = !SampleToolWidget.showPinTags;//flip the state
    IModelApp.viewManager.selectedView?.invalidateDecorations();
    // store.dispatch(setNewPinAnnotation(copyPinSelected));
    return;
}

store.subscribe(HighlightPinAnnotations);