import { ToolbarItemUtilities } from "@itwin/appui-abstract";
import { RenderMode } from "@itwin/core-common";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import EquipmentClient from "../api/equipment";
import { OutlineShapeTool } from "../components/tools/OutlineShapeTool";
import { SyncUiEventDispatcher } from "@itwin/appui-react";
import { getEquipment } from "./GetDetectedObjectsData";
import { EquipmentsTable } from "../components/Tables/EquipmentsTable";
import { DTVActions } from "../../store/Actions";
import { RootState } from "../../store/States";
import { setSelectedObjectStructure, setSiteCoordinate, setTowerData } from "../../store/detectedData/apiDataActionTypes";
import { SyncUiEventIds } from "../../store/redux-types";
import { store } from "../../store/rootReducer";
import { EquipmentPosition } from "./decorators/EditableShapeDecorator";
import { ObjectCategoryTypes, initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";

function select(state: RootState, dataKey: string) {
    return state.detectedData[dataKey];
}

export const HighlightShape = () => {
    return ToolbarItemUtilities.createActionButton(
        "icon-3d", 
        110, 
        "icon-3d", 
        "Highlight Equipment",
        async () => await executeHighlightShape(true),
        {
            isActive: true, 
        }
    );

};

export const executeHighlightShape = async(showMessage:boolean = true)=> {
    const siteCoordinates = select(store.getState(), "siteCoordinate");

    const currentState = store.getState();
    const highlightStates = currentState.dtvState.applicationState.highlightStates;
    if(currentState.dtvState.applicationState.projectStatus.isInsightAvailable){
        store.dispatch(DTVActions.updateHighlightState({...highlightStates, equipment: !highlightStates.equipment}))
        if(highlightStates.equipment){
            executeUnHighlightShape()
        } else SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Equipment_Highlighted);       
    }            

    if (!siteCoordinates) {
        const siteCoord = await EquipmentClient.setSiteCoordinates(currentState.auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);     // Storing site coordinate, for if equipments are edited & require update relative to site <coordinates className="" />
        store.dispatch(setSiteCoordinate(siteCoord));
    }
    let equipmentDataMaps;//select(currentState, "equipmentDataMaps");
    if (!EquipmentsTable.equipNamePositionMap || (EquipmentsTable.equipNamePositionMap as Map<string, EquipmentPosition>).size === 0) {
        const equipFetchVal = await getEquipment(currentState.auth.accessTokenStatePrivateAPI.accessToken!);
        if(equipFetchVal === false){
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error,`No Equipment detected in this tower.`, "", OutputMessageType.Toast));
            return;
        } else {
            equipmentDataMaps = equipFetchVal;
            if(equipFetchVal){

                EquipmentsTable.equipNameInfoMap = new Map(equipFetchVal.equipNameInfoMap);
                 EquipmentsTable.equipNamePositionMap = new Map(equipFetchVal.equipNamePositionMap);
                 EquipmentsTable.equipmentData = [...equipFetchVal.equipmentData];
            }
        }

    }
    let towerStructure = select(currentState, "towerStructureData");
    
    if (!towerStructure) {
        towerStructure = await EquipmentClient.getTowerStructureJson(currentState.auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
        store.dispatch(setTowerData(towerStructure));
    }

    const vp = IModelApp.viewManager.selectedView;
    if (vp) {
        const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
        vp.viewFlags = viewFlags;
    }

    let toolRetVal: boolean = false;

    setTimeout(async () => {
        toolRetVal = await IModelApp.tools.run(OutlineShapeTool.toolId);
        if(toolRetVal){
            if(showMessage) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Equipment are successfully loaded.", "", OutputMessageType.Toast));
        }
    }, 0);

}

export const executeUnHighlightShape = () => {
    const currentState = store.getState();

    SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Equipment_UnHighlighted);
    if(currentState.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Equipment_RM)store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
}

store.subscribe(HighlightShape);