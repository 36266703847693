
import { PropertyRecord } from "@itwin/appui-abstract";
import { getValueInRequiredUnit, getUnit } from "./Tables/BaseTable";
import { IModelApp } from "@itwin/core-frontend";
import { RootState } from "../../store/States";
import { BracingDecorator } from "../tools/decorators/BracingDecorator";
import { store } from "../../store/rootReducer";
import { ConfigManager } from "../../config/ConfigManager";

function select(state: RootState, dataKey: string) {
  return state.detectedData[dataKey];
}

export const getBracingsKeyValue = () => {
//   const record = getData2show()!;
    let record: any;
    const towerdata = store.getState().detectedData.towerStructureData;
    const concreteHeight = ConfigManager.AGL ? Object.entries(towerdata).filter((f) => f[0] === "concrete_height")[0][1] : 0;
    const bracingsDecorator = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("BracingDecorator"))[0] as BracingDecorator;
    let nameValuePair: {name: string, value: string}[] = [];
    if (bracingsDecorator != undefined && bracingsDecorator.selectedBracings != undefined) {
        if (bracingsDecorator.selectedBracings.modelData.type == "Face Bracing") {
            const topph = (bracingsDecorator.selectedBracings.modelData.parentBracingHeights.bracingTopH - bracingsDecorator.selectedBracings.modelData.baseAltitude + concreteHeight).toFixed(4);
            const botth = (bracingsDecorator.selectedBracings.modelData.parentBracingHeights.bracingBotH - bracingsDecorator.selectedBracings.modelData.baseAltitude + concreteHeight).toFixed(4);
            const tH = `${getValueInRequiredUnit(topph)}`;
            const bH = `${getValueInRequiredUnit(botth)}`;
            record = {
                type: bracingsDecorator.selectedBracings.modelData.pattern,
                topH: tH,
                botH: bH,
                horizMB: bracingsDecorator.selectedBracings.modelData.horizMiddleBay,
                horizTB: bracingsDecorator.selectedBracings.modelData.horizTopBay
            };

            nameValuePair.push({name: "Pattern", value: record? record.type:""});

            nameValuePair.push({name: `Top Elevation ${getUnit()} *`, value:record? record.topH:""});

            nameValuePair.push({name: `Bottom Elevation ${getUnit()} *`, value:record? record.botH:""});

            nameValuePair.push({name: "Horizontal Middle Bay", value:record? record.horizMB:""});

            nameValuePair.push({name: "Horizontal Top Bay", value: record?record.horizTB:""});
            nameValuePair.push({name: "Type", value: bracingsDecorator.selectedBracings.modelData.type});
        } else {
            record = {
                type: `${bracingsDecorator.selectedBracings.modelData.pattern[0]}${bracingsDecorator.selectedBracings.modelData.pattern.substring(1).toLowerCase()}`,
                // bracingHeights: bracingsDecorator.selectedBracings.modelData.bracingHeights,
                bracingHeights: (bracingsDecorator.selectedBracings.modelData.bracingHeights - bracingsDecorator.selectedBracings.modelData.baseAltitude + concreteHeight).toFixed(2),
                horizMB: bracingsDecorator.selectedBracings.modelData.horizMiddleBay,
                horizTB: bracingsDecorator.selectedBracings.modelData.horizTopBay
            };

            nameValuePair.push({name: "Pattern", value: record? record.type:""});
            
            nameValuePair.push({name: `Elevation ${getUnit()} *`, value:record? getValueInRequiredUnit(record.bracingHeights) :""});
            nameValuePair.push({name: `Placement at`,value: record.horizMB ? "Middle":"Top"});
            nameValuePair.push({name: "Type", value: bracingsDecorator.selectedBracings.modelData.type});

            // nameValuePair.push({name: `Bottom Elevation ${getUnit()} *`, value:record? record.botH:""});

            // nameValuePair.push({name: "Horizontal Middle Bay", value:record? record.horizMB:""});

            // nameValuePair.push({name: "Horizontal Top Bay", value: record?record.horizTB:""});

        }
    }

  // store.dispatch(getPropertyListStructure(`${pl.objectProperties.objectCategoryType}#${pl.objectProperties.selectedObject}`));

    // convert an angle from degrees to radians
    // const dataProvider: PropertyRecord[] = [];
    // return dataProvider;
    // store.dispatch(getPropertyListStructure(`abc`));

    return nameValuePair;
}
