import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { ConfigManager } from "../../config/ConfigManager";

export default class PinTagClient {
    constructor() { }
    //-------------------------------------------------------------------------
    /* Save New Tag 
     */
    public static async postTag(token: string, tagData: any): Promise<any> {
        if (!tagData) {
            return;
        }
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Tag`;
        return fetch(url, {
            method: "post",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tagData),
        }).then(async (res: Response) => {
            if (!res.ok) {
                IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Saving Failed , Please Retry", "", OutputMessageType.Toast))
                return null;
            }
            IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Tag Saved", "", OutputMessageType.Toast))
            return "success";
        })
    }
     //-------------------------------------------------------------------------
    /* Edit Existing Tag
     */
    public static async putTag(token: string, tagData: any): Promise<any> {
        if (!tagData || !tagData.tagId) {
            return;
        }
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Tag/${tagData.tagId}/`;

        return fetch(url, {
            method: "put",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(tagData),
        }).then(async (res: Response) => {
            if (!res.ok) {
                return null;
            }
            return "success";
        }).then((data: any) => {
            return data;
        });
    }
     //-------------------------------------------------------------------------
    /* Get All Tags
     */
    public static async getPinTags(token: string): Promise<any> {
        const projectId = ConfigManager.projectId;
        const url: string = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Tag`;
        return fetch(url, {
            method: "get",
            headers: {
                Authorization: token,
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return null;
            }
            return response.json();
        }).then((data: any) => {
            return data;
        });
    }
     //-------------------------------------------------------------------------
    /*  Delete Existing Tag
     */
    public static async deleteTag(token: string, tagId: any) {
        const projectId = ConfigManager.projectId;
        const url = `${ConfigManager.openToweriQUrl}/v1.0/${projectId}/Tag/${tagId}`;
        return fetch(url, {
            method: "delete",
            headers: {
                Authorization: token,
                "Content-Type": "application/json",
            },
        }).then(async (response: Response) => {
            if (!response.ok) {
                return "err";
            }
            return "success";
        }).then((data: any) => {
            if (data === "err") {
                return false;
            }
            return data;
        });
    }
}
