import { ToolbarItemUtilities, BadgeType, IconSpecUtilities } from "@itwin/appui-abstract"
import { Decorator, IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import { HighlightEquipment } from "../../tools/HighlightEquipment";
import DefectsClient from "../../api/defects";
import { UiFramework } from "@itwin/appui-react";
import { AccessToken } from "@itwin/core-bentley";
// import { licenseProductIds } from "../../app-ui/frontstages/SampleToolWidget";
import TrackTelemetryApiClient from "../../api/trackTelemetry";
import { ExcelComponent } from "../../components/Excel/ExcelComponent";
import { PropertyTableType, RootState } from "../../../store/States";
import { store } from "../../../store/rootReducer";
import { DefectsDecorator } from "../../tools/decorators/DefectsDecorator";
import DatabaseApiClient, { OrganizationAddOnProductProjectInputType } from "../../../services/graphql/database-api/database-api-client";
import { licenseProductIds } from "../../../store/redux-types";
import { DTVActions } from "../../../store/Actions";
import { addToBuilt3DObjectIdsMap } from "../../../store/detectedData/apiDataActionTypes";
import ToolActionHandler from "../TreeVisualizer/As-Built/ToolsActionHandlers";
import { DefectData, DefectsTable } from "../Tables/DefectsTable";
import { DigitalTwinViewerApp } from "../../../api/DigitalTwinViewerApp";
import { deductLicense } from "../LicenseWorkflow";
import { ConfigManager } from "../../../config/ConfigManager";

export enum TableEnum {
  Panel,
  Modification,
  Mount,
  Discrete,
  Attachment,
  Appurtenance,
  Isolated,
  DrilledPier,
  Reaction,
  Blank,
  Pole,
  Defect, // This is temporary
  Equipment,
}

let projectId: string, openToweriQUrl: string, privateAccessToken: AccessToken, towerId: string, checkForLicense: boolean, isInsiteSupported: boolean = false, licenseEnabled: boolean = false;
const listener = () => {
    setProjectIdUrl(store.getState());
}

store.subscribe(listener);

function setProjectIdUrl(state: RootState) {
    towerId = ConfigManager.towerId!;
    projectId = ConfigManager.projectId!;
    openToweriQUrl = ConfigManager.openToweriQUrl!;
    privateAccessToken = state.auth.accessTokenStatePrivateAPI.accessToken!;
    checkForLicense = DigitalTwinViewerApp.checkForLicense!;
    // checkForLicense = state.dtvState.applicationState.checkForLicense!;
    // licenseEnabled = state.dtvState.applicationState.licenseStates.inspectionLicense.licenseEnabled!;
    licenseEnabled = DigitalTwinViewerApp.licenseStates.inspectionLicense.licenseEnabled!;
    isInsiteSupported = ConfigManager.INsiteImageUrl.length > 0;
    return {projectId, openToweriQUrl, privateAccessToken, towerId, checkForLicense, licenseEnabled, isInsiteSupported}
}

const select = (state: RootState, dataKey: string) => {
    return state.dtvState.featureControls[dataKey];
  }
  

export const displayDitectedDefectExecute = async () => {
  // TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_AUTO_DETECTED_DEFECTS");
  if (!isInsiteSupported) {
  //   this.showNotSupportedNotification();
    return;
  }
  const tokenString = store.getState().auth.accessTokenStatePrivateAPI.accessToken!;
  const iModel = UiFramework.getIModelConnection();
  let allDetectedDefects: DefectData[] = [];
  let allDefectsDecorator: any = IModelApp.viewManager.decorators.filter(e=>e.constructor.name=="DefectsDecorator");
  // Created the allDefectsDecorators to contain all the types of defects viz Pin Defects & AIML Rusty/Shelter defects
  if (!allDefectsDecorator.length) {
    const defects = await DefectsTable.getData(iModel!);
  //   SampleToolWidget.allDetectedDefects = SampleToolWidget.allDetectedDefects?.concat(defects);
    allDetectedDefects = allDetectedDefects?.concat(defects);
      
    const baseAlt = await DefectsClient.getBaseAltitude(tokenString);
    const pinDefects = await DefectsTable.getPinsData(iModel!);
    pinDefects.map((e) => e.info = {baseAlt});
  //     SampleToolWidget.allDetectedDefects = SampleToolWidget.allDetectedDefects?.concat(pinDefects);

      //if decorator exist use the existing to append decorator entites
       let hasDecorator: boolean = false;
       for (const dec of IModelApp.viewManager.decorators) {
          if (dec.constructor.name.includes("DefectsDecorator")) {
               hasDecorator = true;
               (dec as DefectsDecorator).terminate();
               (dec as DefectsDecorator).loadPinDefectMarkers(allDetectedDefects!);
               allDefectsDecorator = [dec];
               IModelApp.viewManager.selectedView?.invalidateDecorations();
               IModelApp.viewManager.selectedView?.invalidateCachedDecorations(dec);
               setTimeout(() => {store.dispatch(addToBuilt3DObjectIdsMap(new Map((dec as DefectsDecorator).objectIdMap)));}, 0);
          }
       }
      if (!hasDecorator) {
          const allDefs = new DefectsDecorator();
          allDefs.loadPinDefectMarkers(allDetectedDefects!)
          allDefectsDecorator = IModelApp.viewManager.addDecorator(allDefs)!;
          IModelApp.viewManager.selectedView?.invalidateCachedDecorations(allDefs);
          setTimeout(() => {store.dispatch(addToBuilt3DObjectIdsMap(new Map(allDefs.objectIdMap)));}, 0);
      }
      if(checkForLicense){
          // let params: OrganizationAddOnProductProjectInputType = {
          //     ultimateId: getUltimateIdOfUser(),
          //     towerId: store.getState().detectedData.projectDetails.towerId!,
          //     projectId: store.getState().detectedData.projectDetails.projectId,
          //     addOnProductId:licenseProductIds.InspectionTools
          //   }
          //   const retVal: boolean = await DatabaseApiClient.handleProjectAddOnProductLicense(params);
          //   if(retVal)DTVActions.setLicenseCheckState(retVal);
          deductLicense(licenseProductIds.InspectionTools);
      }
  }
  else
  {
      //Clean and delete the decorator when closing the tool
      for (const dec of IModelApp.viewManager.decorators) {
          if (dec.constructor.name.includes("DefectsDecorator")) {
              (dec as DefectsDecorator).terminate();
              IModelApp.viewManager.dropDecorator(dec);
          }
      }

  //     this._allDefectDecorator = undefined;//this is only cleaning the local object not the object that has been loaded into the viewManager.

  //     SampleToolWidget.allDetectedDefects = [];

  //     if ((IModelApp as any).listCallback) {
  //         (IModelApp as any).listCallback(ListEnum.TowerInfo);
  //     }
  //     if (this._blankIModel) {
  //     // const currDef = FrontstageManager.activeFrontstageDef?.getStagePanelDef(StagePanelLocation.Right)?.findWidgetDef("PropertyListWidget");
  //     // currDef?.setWidgetState(WidgetState.Hidden);
  //     } else if ((IModelApp as any).listCallback) {
  //         (IModelApp as any).listCallback(SampleToolWidget.currentList);
  //     }
  //     return;
  }
  IModelApp.viewManager.selectedView?.invalidateDecorations();
}

export const detectRustyScrewsExecute = async () => {
  const statusDefect = await DefectsClient.getDefectDetectionStatusJson(privateAccessToken);
  if (statusDefect?.info === "AI/ML detection running for screws!") {
        IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Automatic detection of defects was already started. After a few minutes, click on “Display Detected Defects” button. You will see camera icons in the 3D view that indicate the images containing potential defects.", "", OutputMessageType.Toast));
  } else {
    const detectionType = "SCREWS";
    // this.setScrewTrue();
    IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Automatic detection of defects has started. After a few minutes, click on “Display Detected Defects” button. You will see camera icons in the 3D view that indicate the images containing potential defects.", "", OutputMessageType.Toast));
    const recordData = await DefectsClient.postDefectDetectionRunJson(privateAccessToken, detectionType);

    if (recordData !== "successful")IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, `Failed to run "Detect Rusty Screws"! Please try after sometime.`, "", OutputMessageType.Toast));

    if(checkForLicense && recordData == "successful")deductLicense(licenseProductIds.InspectionTools);

    defectStatus(detectionType);
  }
}

export const detectRustySheltersExecute = async () => {
  const statusDefect = await DefectsClient.getDefectDetectionStatusJson(privateAccessToken);
  if (statusDefect?.info === "AI/ML detection running for shelter!") {
          IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Automatic detection of defects was already started. After a few minutes, click on “Display Detected Defects” button. You will see camera icons in the 3D view that indicate the images containing potential defects.", "", OutputMessageType.Toast));
  } else {
      const detectionType = "SHELTER";
      // this.setShelterTrue();
      IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Automatic detection of defects has started. After a few minutes, click on “Display Detected Defects” button. You will see camera icons in the 3D view that indicate the images containing potential defects.", "", OutputMessageType.Toast));
      const recordData = await DefectsClient.postDefectDetectionRunJson(privateAccessToken, detectionType);
      if (recordData !== "successful")IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Error, `Failed to run "Detect Rusty Shelters"! Please try after sometime.`, "", OutputMessageType.Toast));
      
      if(checkForLicense && recordData == "successful")deductLicense(licenseProductIds.InspectionTools);
      defectStatus(detectionType);
  }
}

export const exportToExcelExecute = () => {
  let info: any = [];
  DefectsTable.getData(UiFramework.getIModelConnection()!).then((data) => {
    info = data;
    if (info.length > 0) {
      ExcelComponent({ tableName: "Defects", info });
      if(checkForLicense)deductLicense(licenseProductIds.InspectionTools);
    }
  });
}

export const showDefectsTableExecute = () => {
  TrackTelemetryApiClient.trackTelemetry("TOWER_ITWIN_TOGGLE_DEFECTS_TABLE");
  store.dispatch(DTVActions.setPropertyTable(PropertyTableType.DM_DEFECTS_TABLE))
  ToolActionHandler.toggleTable(TableEnum.Defect);
  if(checkForLicense)deductLicense(licenseProductIds.InspectionTools);
}


export const getDefectsMenu = () => {
    // const {projectId, openToweriQUrl, privateAccessToken, towerId, checkForLicense, isInsiteSupported} = setProjectIdUrl(store.getState());

    const enableBackgroundMap = ToolbarItemUtilities.createActionButton(
        "AI/ML", 
        100, 
        "icon-location", 
        "Display Detected Defects",
        displayDitectedDefectExecute
    );

  const disableBackgroundMap = ToolbarItemUtilities.createActionButton(
    "DefectRustScrews",
    110,
    "icon-duplicate",
    "Detect Rusty Screws",
    // {isDisabled: this.state.screw || !this.state.licenseStates.inspectionLicense.licenseEnabled,}
    // {isDisabled: false},
    detectRustyScrewsExecute
    );

  const detectRustyShelter = ToolbarItemUtilities.createActionButton(
    "DefectRustShelter",
    110,
    "icon-concrete-block",
    "Detect Rusty Shelters",
    // this.state.shelter || !this.state.licenseStates.inspectionLicense.licenseEnabled,
    detectRustySheltersExecute
    );

  const showDefectsTable = ToolbarItemUtilities.createActionButton(
    "ToggleDefectsTableTool",
    110,
    "icon-table",
    "Show Defects Table",
    //  !this.state.licenseStates.inspectionLicense.licenseEnabled,
    showDefectsTableExecute
);

  const exportToExcel = ToolbarItemUtilities.createActionButton(
    "DefectsExportExcelTool",
    110,
    "icon-export",
    "Export to Excel",
    //  !this.state.licenseStates.inspectionLicense.licenseEnabled,
    exportToExcelExecute
);

    return ToolbarItemUtilities.createGroupButton(
        "display-defects", 
        4000, 
        "icon-status-warning", 
        "Visualize Defects", 
        [
            enableBackgroundMap, 
            disableBackgroundMap,
            detectRustyShelter,
            showDefectsTable,
            exportToExcel
        ], 
        { badgeType: BadgeType.TechnicalPreview }
    );

}

export const defectStatus = (detectionType: string) => {
    const setStatus = setInterval(async () => {
      // const statusDefect = await DefectsClient.getDefectDetectionStatusJson(App.accessToken /*AccessToken is a string now*/);
      const statusDefect = await DefectsClient.getDefectDetectionStatusJson(privateAccessToken/*AccessToken is a string now*/);
      if (detectionType === "SCREWS") {
        if (statusDefect != null && statusDefect.info !== "AI/ML detection running for screws!") {
          IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Defects detection completed for the model.", "", OutputMessageType.Toast));
        //   this.setScrewFalse();
          clearInterval(setStatus);
        } else if (statusDefect == null) {
        //   this.setScrewFalse();
          clearInterval(setStatus);
        }
      } else {
        if (statusDefect != null && statusDefect.info !== "AI/ML detection running for shelter!") {
          IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Defects detection completed for the model.", "", OutputMessageType.Toast));
        //   this.setShelterFalse();
          clearInterval(setStatus);
        } else if (statusDefect == null) {
        //   this.setShelterFalse();
          clearInterval(setStatus);
        }
      }
    }, 15000);
  }


// export const deductLicense = async (productId: number) => {
//     let params: OrganizationAddOnProductProjectInputType = {
//       ultimateId: "",
//       towerId: towerId!,
//       projectId: projectId,
//       addOnProductId:productId
//     }
//     DTVActions.deductLicenseOnFeatureUse(params);
//     let retVal: boolean = false;// await DatabaseApiClient.handleProjectAddOnProductLicense(params);
//     if(retVal)return false;
//     else return true;
// }

export const getUltimateIdOfUser = () => {
    let token = privateAccessToken /*AccessToken is a string now*/;
    let base64Payload = token.split('.')[1];
    let payload = Buffer.from(base64Payload, 'base64');
    let tokenProperties =JSON.parse(payload.toString())
    return tokenProperties.ultimate_site
  }
