import { IconSpecUtilities, ToolbarItemUtilities } from "@itwin/appui-abstract"
import { RenderMode } from "@itwin/core-common";
import { IModelApp, NotifyMessageDetails, OutputMessagePriority, OutputMessageType } from "@itwin/core-frontend";
import axios from "axios";
import { ConfigManager } from "../../config/ConfigManager";
import { DTVActions } from "../../store/Actions";
import { store } from "../../store/rootReducer";
import { HighlightLadderTool } from "../components/tools/HighlightLadderTool";
import { LadderDecorator } from "./decorators/LadderDecorator";
import ladder from "../components/icon/svg/ladder.svg"
import { RootState } from "../../store/States";
import { addToBuilt3DObjectIdsMap, setLadderData, setLadderData3DMaps, setSelectedObjectStructure, setTowerData } from "../../store/detectedData/apiDataActionTypes";
import { ObjectCategoryTypes, initialPropertyListObjectState } from "../../store/detectedData/apiDataTypes";
import { getLadderData } from "../api/ladderClient";
import { SyncUiEventDispatcher } from "@itwin/appui-react";
import { SyncUiEventIds } from "../../store/redux-types";
import EquipmentClient from "../api/equipment";
import { editOnClose } from "../components/HorizontalToolbarItems";
 
export const HighlightLadder = () => {
 

    return ToolbarItemUtilities.createActionButton(
        "Highlight-ladder", 
        110, 
        // "icon-crop", 
        IconSpecUtilities.createWebComponentIconSpec(ladder), 
        "Highlight Ladder",
        async () =>  await executeHighlightLadder(true)
    )
}
    function select(state: RootState, dataKey: string) {
        return state.detectedData[dataKey];
    }
export const executeHighlightLadder = async (showMessage:boolean = true) => {
            try{
                let ladder_data :any
               if(store.getState().detectedData.laddersData.length > 0)ladder_data = store.getState().detectedData.laddersData
               else{try{

                   let data= await getLadderData();
                  if(data && data.status == 200){
                      store.dispatch(setLadderData(data.data));
                      ladder_data = data.data
                  }else if (data == null && showMessage)  IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Ladder Data Not Loaded.", "Ladder data could not be found for this project.", OutputMessageType.Toast));            
               }catch(e){
                if(showMessage)IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Ladder Data Not Loaded.", "Ladder data could not be found for this project.", OutputMessageType.Toast));            
               }
               }
               let towerStructure = select(store.getState(), "towerStructureData");
               if (!towerStructure) {
                   towerStructure = await EquipmentClient.getTowerStructureJson(store.getState().auth.accessTokenStatePrivateAPI.accessToken /*accessTokenPrivate is a string now*/);   // Storing tower structure, for if equipments are edited they can be validated with the tower elevation values.
                   store.dispatch(setTowerData(towerStructure));
               }
               if(ladder_data && ladder_data.length > 0){
                   
                   const vp = IModelApp.viewManager.selectedView;
                   if (vp) {
                       const viewFlags = vp.viewFlags.copy({renderMode: RenderMode.SmoothShade});
                       vp.viewFlags = viewFlags;
                    }
                    
                    let toolRetVal: boolean=false;
                    // const currentHighlightStates = store.getState().dtvState.applicationState.highlightStates;
                    const currentState: RootState = store.getState();
                        if(currentState.dtvState.applicationState.highlightStates.ladder){
                        store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, ladder: false}));
                        if(currentState.detectedData.selectedObjectInformation.objectProperties.objectCategoryType == ObjectCategoryTypes.Ladder_RM)store.dispatch(setSelectedObjectStructure(initialPropertyListObjectState));
                        executeUnHighlightLadder()
                        SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Ladder_UnHighlighted);
                        return;
                    } else {
                        store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, ladder: true}));
                        toolRetVal = await IModelApp.tools.run(HighlightLadderTool.toolId, ladder_data, true, ["All"]);
                        SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Ladder_Highlighted);
                        if(toolRetVal){            
                           if(showMessage) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Info, "Ladders are successfully loaded.", "", OutputMessageType.Toast));
            
                            const md = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
                            store.dispatch(setLadderData3DMaps(new Map(md.nameIdMap)));
                            store.dispatch(addToBuilt3DObjectIdsMap(new Map(md.objectIdMap)));
                        }
                    }

                    // setTimeout(async () => {
                        // const md = IModelApp.viewManager.decorators.filter(e => e.constructor.name.includes("LadderDecorator"))[0] as LadderDecorator;
                        // store.dispatch(setMountingsData3DMaps(new Map(md.nameIdMap)));
                        // store.dispatch(addToBuilt3DObjectIdsMap(new Map(md.objectIdMap)));
                    //   (currTools.filter(e=>e.id == "Select")[0] as ActionButton).execute();   //  Making the select tool enabled after equipment loading for abetter user experience. 
                    //   CoreTools
                    // }, 0);
                    // if (toolRetVal)this.setState({ isMountVisible: true});
                    // this.setState({highlightStates: {...this.state.highlightStates, mount: true}});
                    // store.dispatch(DTVActions.updateHighlightState({...currentHighlightStates, mount: true}));
                  
                } else{
                    const currentState: RootState = store.getState();
                    if(store.getState().detectedData.siteCoordinate && store.getState().detectedData.siteCoordinate.utm.hasOwnProperty("x") && store.getState().detectedData.siteCoordinate.utm.z){
                        if(currentState.dtvState.applicationState.highlightStates.ladder){
                            store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, ladder: false}));
                            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Ladder_UnHighlighted);
                        }else{
                            store.dispatch(DTVActions.updateHighlightState({...currentState.dtvState.applicationState.highlightStates, ladder: true}));
                            SyncUiEventDispatcher.dispatchSyncUiEvent(SyncUiEventIds.RM_Ladder_Highlighted);  
                        }
                    }
                }
            }catch(e){
               if(showMessage) IModelApp.notifications.outputMessage(new NotifyMessageDetails(OutputMessagePriority.Warning, "Ladder Data Not Loaded.", "Ladder data could not be found for this project.", OutputMessageType.Toast));            }
            }

export const executeUnHighlightLadder = () => {
    IModelApp.tools.run(HighlightLadderTool.toolId, [], false);
    editOnClose("LadderDecorator",true);
    return;
}
store.subscribe(HighlightLadder);